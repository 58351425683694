import React from "react";

import { HeadingWCopy, Carousel } from "@molecules";
import { Button, Container, Image, Text } from "@atoms";

const CarouselImageWCopy = ({ items, heading, copy, button }) => {
  return (
    <div>
      <Container variant="sm">
        <div className="relative w-full">
          <div className="mx-auto max-w-lg">
            <HeadingWCopy
              centered
              small
              green
              heading={heading}
              copy={copy}
              inverse
            />
          </div>
          <div className="my-10 w-full">
            <div className="my-10 mb-12 flex flex-wrap justify-center px-6 sm:-mx-4 sm:pb-4">
              <Carousel gradient="black" showMultiple maxVisible={3}>
                {items?.map((item, i) => {
                  return (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      className="w-full max-w-screen px-0 pb-6 text-center sm:px-3"
                    >
                      <div className="relative px-3">
                        <Image {...item.image} aspectratio="4:3" />
                        <div className="my-4 text-green">
                          <Text variant="h7">{item.heading}</Text>
                        </div>
                        <Text variant="body--small">{item.copy}</Text>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
        {button && (
          <div className="-mt-6 flex items-center justify-center">
            <Button to={button.url || button.link}>{button.text}</Button>
          </div>
        )}
      </Container>
    </div>
  );
};

CarouselImageWCopy.defaultProps = {};

export default CarouselImageWCopy;
